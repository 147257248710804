import Base from '../common/Base';
export default class Size extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'languages'
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base,
        path
      } = this.state;
      if (platform !== 'github') return '';
      /**
       * /github/languages/code-size/:user/:repo
       */
      if (type === 'languages') {
        return [base, platform, type, 'code-size', user, repo].join('/');
      }
      /**
       * /github/repo-size/:user/:repo
       */
      if (type === 'repo-size') {
        return [base, platform, type, user, repo].join('/');
      }
      /**
       * /github/size/:user/:repo/:path*
       */
      if (type === 'size' && path) {
        return [base, platform, type, user, repo, path].join('/');
      }
      return '';
    };
  }
}