.success {
  color: #27ae60;
}

.warning {
  color: #f1c40f;
}

.disabled {
  color: #7c7f82;
}
