import Base from '../common/Base';
export default class Activity extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'downloads',
      total: true
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base,
        tag,
        path,
        total
      } = this.state;
      if (platform !== 'github' || !/^(downloads|downloads-pre)/.test(type || '')) return '';
      var baseData = [base, platform, type, user, repo];
      if (path && tag) {
        return [...baseData, tag, path].join('/');
      }
      if (total && tag || type === 'downloads-pre' && tag && total) {
        return [...baseData, tag, 'total'].join('/');
      }
      if (total) {
        return [...baseData, 'total'].join('/');
      }
      return '';
    };
  }
}