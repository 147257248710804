.card {
  .name {
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
  }

  &.library {
    @media only screen and (min-width: 600px) {
      min-width: 20rem;
    }

    @media only screen and (max-width: 600px) {
      min-width: 100%;
    }

    .description {
      flex: 1;
      max-width: 320px;
      box-sizing: border-box;
    }
  }

  &.app,
  &.framework {
    @media only screen and (min-width: 601px) {
      min-width: 32rem;
    }

    @media only screen and (max-width: 600px) {
      min-width: 100%;
    }

    flex: 1;
    max-width: 750px;
  }
}
