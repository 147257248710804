import Base from '../common/Base';
export default class Analysis extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'languages-count'
    });
    this.getUrl = () => {
      var {
        base,
        platform,
        type,
        user,
        repo,
        query
      } = this.state;
      var typePath = '';
      switch (type) {
        case 'languages-count':
          typePath = 'languages/count';
          break;
        case 'languages-top':
          typePath = 'languages/top';
          break;
        case 'search':
          typePath = 'search';
          break;
        default:
          break;
      }
      if (platform !== 'github' || !typePath) return '';
      var baseData = [base, platform, typePath, user, repo];
      if (query) {
        return [...baseData, query].join('/');
      }
      return baseData.join('/');
    };
  }
}