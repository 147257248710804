import Base from '../common/Base';
/**
 * Npm Size
 * 
 * `/bundlephobia/:format/:packageName`
 * npm bundle size:  npm bundle size badge 
 * 
 * `/bundlephobia/:format/:scope/:packageName`
 * npm bundle size (scoped): npm bundle size (scoped) badge  
 * 
 * `/bundlephobia/:format/:packageName/:version`
 * npm bundle size (version):  npm bundle size (version) badge 
 * 
 * `/bundlephobia/:format/:scope/:packageName/:version`
 * npm bundle size (scoped version): npm bundle size (scoped version) badge  
 * 
 */
export default class Size extends Base {
  constructor(props) {
    super(props, {
      format: 'min'
    }, {
      platform: 'npm'
    });
    this.getUrl = () => {
      var {
        base,
        platform,
        format,
        packageName,
        scope,
        version
      } = this.state;
      if (platform !== 'npm') return '';
      var baseData = [base, 'bundlephobia'];
      var url = '';
      if (packageName && format) {
        url = [...baseData, format, packageName].join('/');
      }
      if (packageName && format && scope) {
        url = [...baseData, format, scope, packageName].join('/');
      }
      if (packageName && format && scope && version) {
        url = [...baseData, format, scope, packageName, version].join('/');
      }
      if (packageName && format && version) {
        url = [...baseData, format, packageName, version].join('/');
      }
      return url;
    };
  }
}