.container {
  flex: 1;

  padding: 0 1rem;
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;

  border-radius: 1rem;
}
