import Base from '../common/Base';
/**
 * Npm Size
 * 
 * `/npm/:interval/:packageName`
 * npm downloads: npm downloads per interval badge  
 * 
 * `/npm/:interval/:scope/:packageName`
 * npm downloads (scoped): npm downloads per interval (scoped version) badge  
 * 
 */
export default class Downloads extends Base {
  constructor(props) {
    super(props, {
      interval: 'dm'
    }, {
      platform: 'npm'
    });
    this.getUrl = () => {
      var {
        base,
        platform,
        interval,
        packageName,
        scope
      } = this.state;
      if (platform !== 'npm' || !packageName) return '';
      var url = [base, 'npm', interval];
      if (scope) {
        url.push(scope);
      }
      url.push(packageName);
      return url.join('/');
    };
  }
}