import Base from '../common/Base';
export default class Coverages extends Base {
  constructor(props) {
    super(props, {
      type: 'github'
    }, {
      platform: 'coveralls'
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base
      } = this.state;
      if (platform !== 'coveralls') return '';
      return [base, platform, type, user, repo].join('/');
    };
  }
}