import Base from '../common/Base';
export default class Social extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'followers'
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base
      } = this.state;
      if (platform !== 'github') return '';
      if (type === 'followers') return [base, platform, type, user].join('/');
      return [base, platform, type, user, repo].join('/');
    };
  }
}