import Base from '../common/Base';
export default class Version extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'version-release'
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base,
        branch
      } = this.state;
      var typePath = '';
      switch (type) {
        case 'version-release':
          typePath = 'v/release';
          break;
        case 'version-tag':
          typePath = 'v/tag';
          break;
        case 'package-json':
          typePath = 'package-json/v';
          break;
        case 'manifest-json':
          typePath = 'manifest-json/v';
          break;
        case 'go-mod':
          typePath = 'go-mod/go-version';
          break;
        default:
          break;
      }
      if (platform !== 'github' || !typePath) return '';
      var baseData = [base, platform, typePath, user, repo];
      if (/(go-mod|manifest-json|package-json)/.test(type || '') && branch) {
        return [...baseData, branch].join('/');
      }
      return baseData.join('/');
    };
  }
}