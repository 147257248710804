import Base from '../common/Base';
export default class Activity extends Base {
  constructor(props) {
    super(props, {
      platform: 'github',
      type: 'commits-since'
    });
    this.getUrl = () => {
      var {
        type,
        platform,
        user,
        repo,
        base,
        interval,
        variant,
        version,
        branch
      } = this.state;
      if (platform !== 'github') return '';
      if (type === 'commits-since' && version && branch) {
        return [base, platform, type, user, repo, version, branch].join('/');
      }
      if (type === 'commit-activity' && interval) {
        return [base, platform, type, interval, user, repo].join('/');
      }
      if (type === 'variant' && variant) {
        return [base, platform, type, variant, user, repo].join('/');
      }
      if (type === 'last-commit') {
        return branch ? [base, platform, type, user, repo, branch].join('/') : [base, platform, type, user, repo].join('/');
      }
      if (type && /^release-(date|date-pre)/.test(type)) {
        return [base, platform, type, user, repo].join('/');
      }
      return '';
    };
  }
}