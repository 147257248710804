import Base from '../common/Base';
export default class Codacy extends Base {
  constructor(props) {
    super(props, {}, {
      platform: 'coveralls'
    });
    this.getUrl = () => {
      var {
        base,
        platform,
        projectId,
        branch
      } = this.state;
      if (platform !== 'coveralls') return '';
      if (branch) return [base, platform, projectId, branch].join('/');
      return [base, 'codacy/coverage', projectId].join('/');
    };
  }
}